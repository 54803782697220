import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout } from '_templates'

const CaseStudy = ({ data }) => (
  <Layout>
    <h1>{data.contentfulCaseStudy ? data.contentfulCaseStudy.id : ''}</h1>
    <p>{data.contentfulCaseStudy ? data.contentfulCaseStudy.node_locale : ''}</p>
  </Layout>
)

export const query = graphql`
  query ContentFulPost($slug: String, $locale: String) {
    contentfulCaseStudy(fields: { localeKey: { eq: $locale } }, id: { eq: $slug }) {
      id
      node_locale
    }
  }
`

CaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default CaseStudy
